<template>
  <div>
  
   <div v-show="showAddProjectForm" class="card  offset-1 col-5 float-start" style= "
    height: 80%;
    display: inline-block;
    width: 92%;
    right: 4%;
    margin-bottom: 2%;
    position: absolute;
    z-index: 99;">
 
  <span class="closeBtn" v-on:click="showAddProjectForm=false" > X </span>   
    <h2> プロジェクトの追加 </h2>
    <br>
    <div class="col-8 offset-2" >
 
    <dl class="row">
        <dt class="col-4"> <label> タイトル </label> </dt>
        <dd class="col-8"> <input  class="form-control" type="text" v-model="addingProject.title" /> </dd>
        <dt class="col-4"> <label> 概要  </label> </dt>
        <dd class="col-8">
            <textarea  v-model="addingProject.description" rows="10" class="form-control"></textarea>
        </dd>
    </dl>
    <br>
    <button class="btn btn-dark" v-on:click="addProject" > 追加 </button>
    </div>
   </div>
  
  <div class="col-12" style="display: inline-block;">
  <h1 style="text-align:left;"> プロジェクト </h1>
  
        <div class="card col-12 offset-md-1 col-md-5 float-start" style="height:180px; margin-bottom:2%;">
     <b v-on:click="showAddProjectForm=true" style="font-size:24px;  cursor: pointer;  font-size: 24px; line-height: 180px;">+</b>
  </div>  

    <div v-for="project in showingProjects" :key="project" class="card  col-12 offset-md-1 col-md-5 float-start" style="height:180px; margin-bottom:2%;">
  
   <h4> <span  v-if="!project.editMode"> {{  project.title}}  </span>
   <input v-else class="form-control" style=" font-size: 20px; width: 70%; display: inline-block;" type="text" v-model="project.title" /> 
     <span v-if="project.editMode" v-on:click="updateProject(project)" style="font-size:14px; margin-left:2%; cursor:pointer;" >編集の完了</span>
    <span v-else v-on:click="project.showSubMenu=!project.showSubMenu" style="float: right;
    line-height: 12px;
    font-size: 24px;
    cursor: pointer;
    display: inline-block;
    height: 24px;">...</span> </h4>
  
   <div v-show="project.showSubMenu" class="card offset-8 col-4" style="position: absolute; margin-top: 40px;">
    <ul style="list-style:none; padding:0;"> 
        <li style="cursor: pointer;" >
        <span  v-on:click="project.editMode=!project.editMode; project.showSubMenu=false;">編集 </span>
      
        </li> 
          
        <li style="cursor: pointer;" > <span v-on:click="deleteProject(project)" >削除</span></li>
   </ul>
   </div>
  
  <router-link :to="{ path: '/pointcloud/projects/' + project.title  }"  v-if="!project.editMode" >
  <pre>{{ project.description}}</pre>
  </router-link>
  <div style="padding-left: 3%; padding-right: 3%;" v-else >
  <textarea class="form-control" v-model="project.description"></textarea>
  </div>
 <span style="font-size:14px; overflow:auto; font-weight:500;">作成日 {{moment(project.created_at) .format('YYYY年 MM月 DD日')  }} </span>
  </div>

</div>
<!--
<span v-on:click="showNextProjects( Math.ceil( this.showingProjects.length / bulkDispNum) )" style="cursor:pointer;">
 ▼次の{{ this.bulkDispNum }}件表示  {{   Math.ceil( this.showingProjects.length / bulkDispNum) }} / {{   Math.ceil( this.projectNames.length / bulkDispNum ) }}
</span>
-->
</div>

</template>

<script lang="js" >
import { faCamera ,faFile  } from "@fortawesome/free-solid-svg-icons";
// import { db } from '../modules/app/indexeddb'; 
import { PointCloudConstants} from './const'; 

const moment = require('moment');

export default {
  name: "PointCloudProjects" ,
    components: { } ,
  data()  {
          return {
              bulkDispNum : 5 ,
              projectNames : [] , 
              showingProjects : [],
              showAddProjectForm : false , 
              addingProject :{
                  title : "" ,
                  description : ""
              },
              faCamera : faCamera , 
              faFile : faFile ,
              
         }  ;
  }
  , beforeUnmounte: function (){
  }
  , mounted :async function(  ){
      /*  
  console.log(PointCloudConstants.FileCacheExpireDate);
   const expireDate = moment(new Date()).add(PointCloudConstants.FileCacheExpireDate,'d').toDate();
 console.log(expireDate);
   const d = await db.potree.where('readAt').below(expireDate ).toArray();
  console.log(d);
  */
        this.$emit('show-header');  
    // https://iotcore-inte-api.azurewebsites.net/api/BulkGetFolderMeta?code=kgCiOAju3qIZcXtwTYFtvzAj2sVGZdcxmhyTKo-XaaJ4AzFuFol9AQ==
       await this.showProjects ();
     }
  ,methods:{  
  
         async showProjects (){

             try {
            var res  ;
            var r = "dirname=projects/pointcloud/&flat=false"
            var code = "";
            if(process.env.VUE_APP_API_CODE =="" ){
               code = "?";
            } else {
               code = process.env.VUE_APP_API_CODE + "&";
            }  
            res = await fetch (process.env.VUE_APP_API_URL +"GetFileByHierarchyV2" +  code + r  );    
        
         const json = await res.json();   
         
         this.projectNames =  json.filenames;
         
         const fs =[];
         for ( var  k=0 ;  k < json.filenames.length ;  k++ ){
          //   if(k >=this.bulkDispNum ){ break; }
          
             fs.push ( json.filenames[k] ) ;
         }
         
         const metas = await fetch (process.env.VUE_APP_API_URL + "BulkGetFolderMetaV2" +process.env.VUE_APP_API_CODE  , {
                  method : "POST",
                  headers: {  'Content-Type': 'application/json' },
                  body :JSON.stringify( { "filenames" : fs } )
            });        
            
        const projects =await metas.json()
        var i=0;
        this.showingProjects = []; 
        for  ( var key in projects ){
            if(  i >= this.bulkDispNum ){
                   break;
            } 
            this.showingProjects.push( projects[key] );
            i++;
            } 
        }catch (e) {
             
             console.log( e );
         }
  
         },
         async updateProject (project) {
             this.editMode= false ;
             if ( confirm("編集内容を保存しますか？") ){
                 
                    
                            var option = {
                  method : "POST",
                  headers: {  'Content-Type': 'application/json' },
                  body :JSON.stringify( {
                     path :  project.path ,
                     info : {
                        type : "pointcloud-project" ,
                        title : project.title ,
                        description : project.description ,  
                        created_at : project.created_at
                    }
                  } )
            }  ;

            try{
                let res = await fetch (process.env.VUE_APP_API_URL + 'CreateOrUpdateFolderV2'+process.env.VUE_APP_API_CODE ,
            option );      
             
             const json =await res.json();
              if(json.error===0){
                  
                  this.$emit('show-flash',{ "message": "更新しました。","type": "success"});                     
              } else {
                    
                 this.$emit('show-flash',{ "message": "更新出来ませんでした。","type": "warning"});     
              }
            } catch(e){
                console.log(e) ;
                 this.$emit('show-flash',{ "message": "更新出来ませんでした。","type": "warning"});     
            }
                    
            } else if ( confirm("編集内容を放棄しますか？") ) { 
               this.showProjects(); 
            }
                         
         } ,
         async deleteProject ( project ){
           //  alert( JSON.stringify(project) );
             if ( confirm("本当に削除しますか？") ) {
              try{
                    project.showSubMenu = false ;
               const res= await fetch (process.env.VUE_APP_API_URL + "DeleteFolderV2" +process.env.VUE_APP_API_CODE  , {
                  method : "DELETE",
                  headers: {  'Content-Type': 'application/json' },
                  body :JSON.stringify( { "path" : project.path } )
                });    
                const err = await res.json();
                if ( err.error === 0  ){
                    
                     this.$emit('show-flash',{ "message": project.title + "を削除しました。","type": "success"});     
                    this.showProjects();
                } else {
                     this.$emit('show-flash',{ "message": project.title + "の削除ができませんでした。。","type": "warning"});     
                }
                }catch (e){
                     this.$emit('show-flash',{ "message": project.title + "の削除ができませんでした。。","type": "warning"});   
                } 
          
            }
            
         },
         async showNextProjects ( pos  ){
            
            if (this.projectNames.length <=  this.showingProjects.length ){ return ; }
         
            var npos = this.bulkDispNum * ( pos +1 ) ; 
            npos = ( npos > this.projectNames.length ) ?  this.projectNames.length : npos; 
            const fs = [] ;
            for  ( var cpos = this.bulkDispNum * pos  ;   cpos < npos ; cpos++ ){
                if(  cpos >= npos ){
                   break;
                 } 
            
                fs.push( this.projectNames[cpos] );
            }
            
            const metas = await fetch (process.env.VUE_APP_API_URL + "BulkGetFolderMetaV2" +process.env.VUE_APP_API_CODE  , {
                  method : "POST",
                  headers: {  'Content-Type': 'application/json' },
                  body :JSON.stringify( { "filenames" : fs } )
            });        
          
          const projects = await metas.json();
          // this.showingProjects = []; 
          var i =0;
          for  ( var key in projects ){
            
            if(  i >= this.bulkDispNum ){ break; }        
             
            this.showingProjects.push( projects[key] );
            i++ ;
        }     
             
    },
         moment (text){
           
           return moment(text);  
         },
          async addProject (){
            var option = {
                  method : "POST",
                  headers: {  'Content-Type': 'application/json' },
                  body :JSON.stringify( {
                     path :  "projects/pointcloud/" + this.addingProject.title ,
                     info : {
                        type : "lidar" ,
                        title : this.addingProject.title ,
                        description : this.addingProject.description 
                    }
                  } )
            }  ;

            try{
                let res = await fetch (process.env.VUE_APP_API_URL + 'CreateFolderV2'+process.env.VUE_APP_API_CODE ,
            option );      
                let resp = await res.json();
                if(resp.error === 0){
                    
                    this.$emit('show-flash',{ "message":"プロジェクトを追加しました。","type": "success"});     
                    this.showProjects();
                    this.showAddProjectForm = false ;
                } else if (resp.error === 1) {
                       this.$emit('show-flash',{ "message":"プロジェクトのタイトルが存在します。","type": "warning"});     
                } else {
                    
                      this.$emit('show-flash',{ "message":"プロジェクトの追加に失敗しました。","type": "warning"});     
                }
            } catch (e){
                
                this.$emit('show-flash',{ "message":"プロジェクトの追加に失敗しました。","type": "warning"});     
            }
          }
    }
}

</script>

<style>
h1{ 
    margin-left: 0px !important;
    margin-bottom: 2%;
}
main {
padding : 0 !important;
}
body{
    
    background-color:transparent !important;
}

#three{
    background:black;
}

@media (max-width: 150px) {
    .lil-gui{
        display:none !important;
    }
    #three{
        background:rgba(0,0,0,0.7);
    }
}

@media (max-height: 300px) {
    .lil-gui{
        
        display:none !important;
    }
      #three{
          
        background:rgba(0,0,0,0.7);
    }
}
.closeBtn{
  color:gray;
  font-size : 32px; 
  position: absolute;
  right: 4%;
  margin-top: 1%;
  cursor: pointer;
}
.lil-gui{

    left : 0;
}

</style>
